import { Link } from "react-router-dom";

import logo from "../../assets/media/logo.svg";

import "./index.css";

const Header = () => {
  return (
    <header className="header">
      <Link to="/">
        <img className="header__logo" src={logo} alt="logo" />
      </Link>
    </header>
  );
};

export default Header;

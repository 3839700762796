import FacebookIcon from "../../components/icons/Facebook";
import InstagramIcon from "../../components/icons/Instagram";

import "./index.css";

const SocialIcon = ({ link, children }) => {
  return (
    <a className="footer__icon" href={link} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__row">
        <div className="footer__column">
          <p className="footer__copyright">
            Copyright © 2022 B018.dxb - All Rights Reserved.
          </p>
        </div>

        <div className="footer__column footer__icons">
          <SocialIcon link="https://www.facebook.com/B018.dxb">
            <FacebookIcon />
          </SocialIcon>

          <SocialIcon link="https://www.instagram.com/b018.dxb">
            <InstagramIcon />
          </SocialIcon>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

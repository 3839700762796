import { Suspense, lazy } from "react";
import { Routes as Switch, Route, Navigate } from "react-router-dom";

import Spinner from "../components/Spinner";

const Homepage = lazy(() => import("../pages/Homepage"));
const PageNotFound = lazy(() => import("../pages/PageNotFound"));

const Routes = () => {
  return (
    <Suspense fallback={<Spinner color="white" />}>
      <Switch>
        <Route exact path="/" element={<Homepage />} />

        <Route exact path="/home" element={<Navigate to="/" />} />

        <Route exact path="*" element={<PageNotFound />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;

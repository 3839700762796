import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./routes";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

const App = () => {
  return (
    <Router>
      <div className="page">
        <Header />

        <main className="full-page-height">
          <Routes />
        </main>

        <Footer />
      </div>
    </Router>
  );
};

export default App;
